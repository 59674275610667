import * as React from 'react';
import { motion } from 'framer-motion';

import SEO from '../components/seo';
import ArrowUpIcon from '!svg-react-loader!../images/vectors/arrow_up.svg';
import SmartRepLogo from '!svg-react-loader!../images/vectors/smart-rep-logo.svg';
import './index.scss';
import ProductCard from '../components/product-card';

const SmartRepPage = () => {
  const description = `Engage with doctors at their convenience. Manage product detailing and distribution. Get feedback from doctors and patients.`;
  const links = [
    { id: 'pharmaceuticals', title: 'Pharmaceutical Companies', linkTo: '/SR-Pharmaceuticals' },
    { id: 'diagnostic-labs', title: 'Diagnostic Labs', linkTo: '/SR-Diagnostics' }
  ];
  return (
    <div>
      <SEO title="Smart Assistant" />
      <div className="container-fluid main-container">
        <ProductCard
          title={'Smart Rep'}
          productImage={SmartRepLogo}
          description={description}
          links={links}
        />
      </div>
      <div className={`page-footer-fixed`}>
        <motion.div
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ repeat: Infinity, repeatDelay: 2, duration: 0.4 }}
        >
          <ArrowUpIcon />
        </motion.div>
        <span className={`page-footer-text`}>swipe up for MyHealthBook</span>
      </div>
    </div>
  );
};

export default SmartRepPage;
